<template>
    <div class="delivery-service-search mb-10">
      <v-row no-gutters justify="center" align="center" class="full-width-background-wrap" ref="search">
        <v-container class="inner-content">
          <v-col cols="12" align="center" class="">
            <h2 class="mb-6 mt-6">Gib deine Postleitzahl ein</h2>
            <v-form class="plz-search-form" align="center" @submit.prevent="submit"  ref="form" >
              <v-text-field
                  v-model="zipCode"
                  class="plz-search-input ma-0"
                  label="Postleitzahl hier eintippen"
                  solo
                  :error-messages="inputFieldIsValid && zipCodeDoesntExist ? 'Diese Postleitzahl existiert nicht. Bitte überprüfe deine Eingabe.' : ''"
                  :rules="numberRules"
              ></v-text-field>
              <v-btn
                  class="submit-button"
                  icon
                  color="black"
                  @click="submit"
                  light
              >
                <v-icon v-if="!searchInProgress" color="#292929">mdi-chevron-right</v-icon>
                <v-progress-circular v-else style="height: 18px; width: 18px;"
                                     indeterminate
                                     color="#292929"
                ></v-progress-circular>
              </v-btn>
            </v-form>
            <p class="mb-8">Wir prüfen für dich, ob ein Lieferdienst grundsätzlich an dieser Postleitzahl verfügbar ist. Beachte: Manchmal passiert es, dass einzelne Straßen außerhalb des Liefergebietes liegen – ob das der Fall ist, erfährst du auf der Seite des jeweiligen Anbieters, indem du deine genaue Adresse eingibst.</p>
          </v-col>
        </v-container>
      </v-row>

      <!-- Result Boxes-->
      <v-row justify="center" v-if="searchTriggered" ref="searchResult">
        <v-col cols="12" align="center">
          <h2 class="mb-10">Wähle den für dich passenden Lieferdienst aus</h2>
        </v-col>
        <v-col v-for="(supplier, i) in searchResultSuppliers" :key="i" class="box-item pa-2" cols="12" :md="dynamicCols" sm="6">
          <div class="box-content-wrapper">
            <div class="logo-container">
              <img class="delivery-service-logo" :src="deliveryServiceContent(supplier.id).image" :alt="deliveryServiceContent(supplier.id).altTag">
            </div>

            <!--       ToDo: remove that special spacer as soon as none of the delivery services have any voucher code -->
            <!--          <div class="desktop-spacer" style="height: 117px;" v-if="deliveryServiceContent(supplier.id).deliveryServiceName === 'Lieferando' && !$vuetify.breakpoint.xs"></div>-->

            <div class="content pt-8 pb-8 pl-6 pr-6 align-center">
              <p class="voucher-hint" v-html="deliveryServiceContent(supplier.id).voucherHint" v-if="deliveryServiceContent(supplier.id).voucherHint"></p>

              <a class="mdButton mb-4" @click="confirmLinkAndRedirect(supplier.id, deliveryServiceContent(supplier.id).link)">{{ deliveryServiceContent(supplier.id).buttonLabel }}</a>
              {{ supplier.text }}

              <p class="minimum-order-value" v-if="deliveryServiceContent(supplier.id).minimumOrderValue">
                Mindestbestellwert: {{deliveryServiceContent(supplier.id).minimumOrderValue}} Euro <br>
                Code beim Bezahlvorgang eingeben.
              </p>
            </div>

          </div>

        </v-col>
      </v-row>
      <v-row justify="center" v-if="searchTriggered">
        <v-col v-for="(supplier, index) in searchResultSuppliers" :key="index" class="box-item pa-2" cols="12" :md="dynamicCols" sm="6">
          <p class="legal-line">
            <sub style='bottom: 0.5em;'>{{ deliveryServiceContent(supplier.id).legalLineHintNumber }}</sub> {{deliveryServiceContent(supplier.id).legalLine}}
          </p>
        </v-col>
      </v-row>
    </div><!--Search Form-->
</template>

<script>
import lieferandoLogo from "@/assets/images/deliveryServices/Lieferando.png";
import uberEatsLogo from "@/assets/images/deliveryServices/UberEats.png";
import woltLogo from "@/assets/images/deliveryServices/Wolt.png";
import axios from 'axios'
import router from "@/router";

export default {
  name: 'SearchComponent',
  components: {},
  data: () => ({
    zipCode: '',
    suppliers: [],
    searchTriggered: false,
    formHasErrors: false,
    numberRules: [],
    inputFieldIsValid: false,
    searchInProgress: false,
    searchResultSuppliers: [],
    zipCodeErrorResponse: [],
    textLinkConfirmationMessage: "Verlassen der McDonald’s-Websites von der McDonald’s Corporation-Website zu einer Website, die von einem Dritten kontrolliert wird, der nicht mit McDonald’s verbunden ist. Der Inhalt und die Richtlinien, einschließlich der Datenschutzbestimmungen, der von Ihnen betretenen Website können von den Ansichten und Richtlinien von McDonald’s abweichen. Stellen Sie sicher, dass Sie die Richtlinien der von Ihnen besuchten Websites überprüfen. McDonald’s ist nicht verantwortlich für die Meinungen, Richtlinien, Aussagen oder Praktiken eines anderen Unternehmens.",
    generatedContent: ["Erster Absatz", "Zweiter Absatz", "Dritter Absatz"]

  }),
  mounted() {
    // ID der Ziel-Div
    const targetDivId = "aloha";

    // Ziel-Div auswählen
    const targetDiv = document.getElementById(targetDivId);

    // Generierten Inhalt an die Ziel-Div anhängen
    this.searchResultSuppliers.forEach(supplier => {
      const paragraph = document.createElement("p");
      paragraph.textContent = '*' + this.deliveryServiceContent(supplier.id).legalLine;
      targetDiv.appendChild(paragraph);
    });
  },
  methods: {
    searchZipCode(zipCode){
      this.searchInProgress = true;
      axios
          // PROD
          .get('https://mcdelivery.de/mcd-mchome/suppliers/' + zipCode)
          // STAGE
          // .get('https://mchome-stag.mcdonalds.de/mcd-mchome/suppliers/' + zipCode)
          .then(response => {
            this.inputFieldIsValid = true;
            this.searchResultSuppliers = []
            this.zipCodeErrorResponse = []

            this.searchResultSuppliers = response.data.suppliers
            this.zipCodeErrorResponse = response.data.errors.error

            // console.log(response.data)
            if(response.data.errors.error) {
               // console.log('PLZ EXISTIERT NICHT')
            }
            if(this.searchResultSuppliers.length < 1 && !this.zipCodeErrorResponse) {
              router.push({name: 'Vote', query: { plz: this.zipCode }})
            }
            if(this.searchResultSuppliers.length > 0 && !this.zipCodeErrorResponse) {
              this.searchTriggered = true;
              this.scrollToSearchResults()
            }

            this.searchInProgress = false;
          }).catch((error) => {
            console.log(error)
      })
    },
    scrollToSearchResults() {
      setTimeout(() => {
        this.$refs["searchResult"].scrollIntoView({ behavior: "smooth" })
      }, "300")
    },
    deliveryServieTrackingName(deliveryService) {
      let selectedDeliveryService = ''
      if (deliveryService && deliveryService === 'LIEFERANDO') {
        selectedDeliveryService = "Lieferando";
      } else if (deliveryService && deliveryService === 'UBEREATS') {
        selectedDeliveryService = "Uber Eats";
      } else if (deliveryService && deliveryService === 'WOLT') {
        selectedDeliveryService = "Wolt";
      }

      return selectedDeliveryService;
    },
    deliveryServiceContent(deliveryService) {
      let deliveryServiceName = ''
      let serviceLink = ''
      let imgSrc = ''
      let altTag = ''
      let voucherCode = ''
      let minimumOrderValue = ''
      let legalLineText = ''
      let legalLineHintNumber = ''
      let voucherHint = ''
      if (deliveryService && deliveryService === 'LIEFERANDO') {
        // LIEFERANDO CONTENT
        deliveryServiceName = "Lieferando";
        imgSrc = lieferandoLogo;
        altTag = 'McDelivery Lieferando Logo'
        serviceLink = "https://www.lieferando.de/mcdonalds";
        voucherCode = '5EURMCD';
        minimumOrderValue = '';
        legalLineText = "Einlösbar in der App von Lieferando, Uber Eats und Wolt in Deutschland oder auf den jeweiligen Websites. Bitte Mindestbestellwert und mögliche Liefer-/Servicegebühr beachten. Gutscheine dürfen nicht verkauft werden und können nicht ausgezahlt oder mit anderen Gutscheinen kombiniert werden. Finde die aktuellen Liefergebiete auf www.mcdelivery.de.";
        legalLineHintNumber = '1'
        voucherHint = "<b class='rabatt-text'>5 Euro Rabatt</b> <br> für deine nächste Bestellung<sub style='bottom: 0.5em;'>"+ legalLineHintNumber +"</sub><br> <br>" + "<b class='voucherCode'>" + voucherCode + "</b>"
      } else if (deliveryService && deliveryService === 'UBEREATS') {
        // UBEREATS CONTENT
        deliveryServiceName = "Uber Eats";
        imgSrc = uberEatsLogo;
        altTag = 'McDelivery Uber Eats Logo'
        serviceLink = "https://www.ubereats.com/de/brand/mcdonalds";
        voucherCode = '5EURMCD';
        minimumOrderValue = '';
        legalLineText = "Einlösbar in der App von Lieferando, Uber Eats und Wolt in Deutschland oder auf den jeweiligen Websites. Bitte Mindestbestellwert und mögliche Liefer-/Servicegebühr beachten. Gutscheine dürfen nicht verkauft werden und können nicht ausgezahlt oder mit anderen Gutscheinen kombiniert werden. Finde die aktuellen Liefergebiete auf www.mcdelivery.de.";
        legalLineHintNumber = '2'
        voucherHint = "<b class='rabatt-text'>5 Euro Rabatt</b> <br> für deine nächste Bestellung<sub style='bottom: 0.5em;'>"+ legalLineHintNumber +"</sub><br> <br>" + "<b class='voucherCode'>" + voucherCode + "</b>"
      } else if (deliveryService && deliveryService === 'WOLT') {
        // WOLT CONTENT
        deliveryServiceName = "Wolt";
        imgSrc = woltLogo;
        altTag = 'McDelivery Wolt Logo'
        serviceLink = "https://wolt.com/de/discovery/deu-mcdonalds";
        voucherCode = '5EURMCD';
        minimumOrderValue = '';
        legalLineText = "Einlösbar in der App von Lieferando, Uber Eats und Wolt in Deutschland oder auf den jeweiligen Websites. Bitte Mindestbestellwert und mögliche Liefer-/Servicegebühr beachten. Gutscheine dürfen nicht verkauft werden und können nicht ausgezahlt oder mit anderen Gutscheinen kombiniert werden. Finde die aktuellen Liefergebiete auf www.mcdelivery.de.";
        legalLineHintNumber = '3'
        voucherHint = "<b class='rabatt-text'>5 Euro Rabatt</b> <br> für deine nächste Bestellung<sub style='bottom: 0.5em;'>"+ legalLineHintNumber +"</sub><br> <br>" + "<b class='voucherCode'>" + voucherCode + "</b>"
      }

      return {
        deliveryServiceName: deliveryServiceName,
        buttonLabel: deliveryServiceName ?  "Bei " + deliveryServiceName + " bestellen" : '',
        image: imgSrc,
        altTag: altTag,
        link: serviceLink,
        voucherCode: voucherCode,
        minimumOrderValue: minimumOrderValue,
        legalLineHintNumber: legalLineHintNumber,
        voucherHint: voucherHint,
        // voucherHint: "<b class='rabatt-text'>8 Euro Rabatt</b> <br> für deine erste Bestellung<sub style='bottom: 0.5em;'>"+ legalLineHintNumber +"</sub><br> <br>" + "<b class='voucherCode'>" + voucherCode + "</b>",
        legalLine: legalLineText,
      }
    },


    submit () {
      this.inputFieldIsValid = false;
      this.numberRules = [
        v => !!v || 'Pflichtfeld',
        v => v.length >= 5 || 'Keine gültige Postleitzahl',
        v => v.length <= 5 || 'Keine gültige Postleitzahl',
        v => /^\d+$/.test(v)||'Keine gültige Postleitzahl',
      ]

      let self = this
      setTimeout( () => {
        if (self.$refs.form.validate()){
          this.matomoTrackingEventSearchPostCode();
          this.searchTriggered = false;
          this.searchZipCode(this.zipCode);
          // console.log('form VALID')
        } else {
          // console.log('form invalid')
          // this.$refs.form.validate()
          return false
        }
      })
    },
    confirmLinkAndRedirect(deliveryService, link) {

      if (confirm(this.textLinkConfirmationMessage) === true) {

        this.matomoTrackingEventDeviveryServiceClicked(deliveryService);

        window.location.href = link;

      } else {

        return false;

      }

    },
    matomoTrackingEventSearchPostCode() {
      window._paq.push(['trackEvent', 'Website Modules', 'Enter Postcode', this.zipCode]);
    },
    matomoTrackingEventDeviveryServiceClicked(deliveryService) {
      window._paq.push(['trackEvent', 'Conversions', 'Delivery Service', this.deliveryServieTrackingName(deliveryService)]);
    },
  },
  computed: {
    // inputFieldIsValid() {
    //   return this.$refs.form.validate()
    // },
    dynamicCols(){
      if (this.suppliers.length <= 3) {
        return 4
      } else {
        return 3
      }
    },
    zipCodeDoesntExist() {
      if (this.zipCodeErrorResponse) {
        return this.zipCodeErrorResponse.length > 0
      } else{
        return false;
      }
    }
  },
  watch: {
    zipCode () {
      this.numberRules = []
      this.inputFieldIsValid = false;
    }
  },
}
</script>


<style scoped>
/* ######## SEARCH FORM CSS ######## */
.full-width-background-wrap {
  background: #faf9f7;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-top: 0;
}

.inner-content {
  max-width: 1168px;
}

.plz-search-form {
  position: relative;
}


.submit-button{
  position: absolute;
  top: 6px;
  right: 10px;
  background: #FFBC0D;
  border-radius: 4px;
}

/* ######## SEARCH RESULT CSS ########*/
.logo-container {
  max-width: 100%;
  background: #E7E8E9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.plz-search-form {
  max-width: 400px;

}

.plz-search-input {}

.delivery-service-logo {
  width: 100%;
  height: auto;
}

.box-item {}

.box-content-wrapper {
  border: 1px solid #CECECE;
  border-radius: 0 0 4px 4px;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.legal-line {
  font-size: 10px;
  line-height: 1.3;
  padding: 5px 10px;
}

.voucher-hint {
  font-size: 13px;
  line-height: 1.3;
  padding: 5px 10px;
}

.minimum-order-value {
  font-size: 13px;
  line-height: 1.3;
  padding: 5px 10px;
  font-family: 'Speedee';
  font-weight: 700;
}

</style>

<style lang="scss">
/*UNSCOPED INPUT FIELD CSS*/
.delivery-service-search {

  .v-input__slot {
    box-shadow: none !Important;
    font-family: "Speedee";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #292929;
    border: 2px solid #FFBC0D;
    border-radius: 4px;
  }

  .v-label {
    color: #ADADAD !Important;
  }

  .v-ripple__container {
    opacity: 0 !important;
  }

  .v-messages__wrapper {
    text-align: center;
  }

  .v-messages__message {
    /*height: 18px;*/
    /*line-height: 14px;*/
    /*font-size: 16px;*/

    height: auto;
    line-height: 22px;
    font-size: 16px;
  }

  .voucherCode {
    font-size: 20px;
  }

  .rabatt-text {
    font-size: 24px;
  }
}
</style>
